import React from 'react';
import styles from './DownloadPage.module.scss';

const DownloadPage: React.FC = () => {
    return (
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.about_item_container}>
                    <div className={styles.about_item_title}>
                        Download Application
                    </div>
                    <div className={styles.about_item_description}>
                        Our application makes it effortless to integrate multiple AI models, visualize their
                        input/output in real-time, and share your findings with collaborators.
                        <br/><br/>
                        <ul>
                            <li>Easy Integration: Connect multiple AI models with just a few clicks, no complex setup
                                required.
                            </li>
                            <li>Real-Time Results: Quickly see the input and output from different models, allowing for
                                fast experimentation.
                            </li>
                            <li>Collaboration Ready: Share your workflows, models, and results with others effortlessly,
                                fostering collaboration and innovation.
                            </li>
                            <li>Flexible and Scalable: Designed to handle small and large AI projects, our platform
                                adapts to your needs.
                            </li>
                        </ul>
                    </div>

                    <button disabled={true}>Download for Windows - Coming soon</button>
                    <br/>
                    <button disabled={true}>Download for LINUX - Coming soon</button>
                    <br/>
                    <button disabled={true}>Download for MacOS - Coming soon</button>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
