import {loadStripe} from '@stripe/stripe-js';

import React, {useEffect, useState} from 'react';
import styles from './PricingPage.module.scss';
import {Link} from "react-router-dom";
import routes from "../routes";
import {CheckoutSubscription, SubscriptionCurrentPlan} from "../services/paymentService";
import {PRICING_PLANS} from "../config/defaultConfig";
import {APISupremumSignInUsingGoogleCredential, useAuth} from "../services/authService";
import {useGoogleLogin} from '@react-oauth/google';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51QCBqLDvhkwdLeWpFX70oYrcYR2b4K4Q6SnChM1YpprtIV2k5XNB6hztvG2HKYWdDQqbDehYCtn2XnnbJMrPrgOg00DNYomHTg');

const PricingPage: React.FC = () => {
    const [currentPlan, setCurrentPlan] = useState<string | undefined>(undefined);
    const auth = useAuth();

    useEffect(() => {
        const fetchCurrentPlan = async () => {
            const plan_id = await SubscriptionCurrentPlan();
            setCurrentPlan(plan_id);
        };
        fetchCurrentPlan();
    }, []);

    const handleCheckout = async (plan: string) => {
        try {
            const session = await CheckoutSubscription(plan);
            const stripe = await stripePromise;
            await stripe?.redirectToCheckout({sessionId: session.id});
        } catch (error) {
            console.error('Checkout error:', error);
        }
    };

    const handleGoogleLoginSuccess = async (credentialResponse: any) => {
        try {
            const response = await APISupremumSignInUsingGoogleCredential(credentialResponse);
            const user_data = response.data.user;
            auth.signIn({
                ...user_data,
                session_id: response.data.session_id
            });
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onError: () => console.log('Login Failed'),
    });

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.pricing}>
                    <div className={styles.pricing_wrapper}>
                        <h1 className={styles.pricing_title}>Pricing Plans</h1>
                        <p className={styles.pricing_desc}>Choose the plan that best fits your needs.</p>
                        <div className={styles.pricing_cards}>

                            {Object.values(PRICING_PLANS).map((plan) => (
                                <div className={styles.pricing_card} key={plan.name}>
                                    <h2 className={styles.pricing_card_title}>{plan.name}</h2>
                                    <p className={styles.pricing_card_price}>
                                        {typeof plan.price === 'number' ? `$${plan.price}` : plan.price}
                                    </p>
                                    <ul className={styles.pricing_card_list}
                                        dangerouslySetInnerHTML={{__html: plan.description}}/>
                                    {currentPlan === plan.name ? (
                                        <span className={styles.current_plan_button}>Current Plan</span>
                                    ) : (
                                        typeof plan.price === 'number' ? (
                                            <button
                                                className={styles.pricing_card_button}
                                                onClick={() => auth.user ? handleCheckout(plan.id) : googleLogin()}
                                            >
                                                Get Started
                                            </button>
                                        ) : (
                                            <Link className={styles.pricing_card_button} to={routes.about}>Contact
                                                Us</Link>
                                        )
                                    )}
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PricingPage;
