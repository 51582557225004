import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import styles from './AIFlow.module.scss';
import {
    CustomNodeGenAIModalState,
    CustomNodeStatus,
    CustomNodeType,
    IAPILoadFlow,
    IFlowNodeSource,
    INodeData
} from "../interfaces/d";
import {Slide, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactFlow, {
    addEdge,
    Background,
    Connection,
    Edge,
    Node,
    ReactFlowProvider,
    useEdgesState,
    useNodesState
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../components/react-flow/custom.scss';
import ImageLoaderNode from "../components/react-flow/CustomImageLoaderNode";
import ImageViewNode from "../components/react-flow/CustomImageViewNode";
import Txt2ImgInputNode from "../components/react-flow/Txt2ImgInputNode";
import TextInputNode from "../components/react-flow/TextCustomNode";
import FloatInputCustomNode from "../components/react-flow/FloatInputCustomNode";

import ModalForGenAINode from "../components/react-flow/ModalForGenAINode";
import {
    convertToIFlowNodes,
    getFlowNodeList,
    loadFlowData,
    pollFlowData,
    renameFlow,
    uploadFlowData
} from "../services/flowService";
import NodeManager from "../components/react-flow/NodeManager";
import DefaultCustomNode from "../components/react-flow/DefaultCustomNode";
import {onDragOver, parseDragData} from "../components/react-flow/NodeListComponent";

import {APPLICATION_EXECUTE_PREFIX, FLOW_DATA_POLLING_INTERVAL} from "../config/defaultConfig";
import {IONodes} from "../components/react-flow/IONodes";
import {ModelList} from "../components/react-flow/ModelList";
import {useNavigate, useParams} from "react-router-dom";
import routes from "../routes";
import LibraryIcon from "../components/react-flow/LibraryIcon";
import LibraryModal from "../components/react-flow/LibraryModal";
import {useAuth} from "../services/authService";
import TextViewCustomNode from "../components/react-flow/TextViewCustomNode";
import CustomControls from "../components/react-flow/CustomControls";
import ExecuteApplicationIcon from "../components/react-flow/ExecuteApplicationIcon";

interface AIFlowProps {
    isCopy?: boolean;
}

let flow_uuid: string = '';
let pollingTimer: NodeJS.Timeout | undefined;

const AIFlow: React.FC<AIFlowProps> = ({isCopy = false}) => {
    const auth = useAuth();
    const [zoomLevel, setZoomLevel] = useState(1);

    const [flowName, setFlowName] = useState<string>('');

    const [modelData, setModelData] = useState<IFlowNodeSource[]>([]);
    const [IONodeData, setIONodeData] = useState<IFlowNodeSource[]>([]);
    const [filter, setFilter] = useState('');

    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [flowCreated, setFlowCreated] = useState(Date.now());

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalState, setModalState] = useState(CustomNodeGenAIModalState.OFF);

    // Library Modal
    const [isLibraryModalVisible, setLibraryModalVisible] = useState(false);

    // Flow data
    const [flowData, setFlowData] = useState<IFlowNodeSource[] | undefined>(undefined);
    const nodeTypes = useMemo(() => ({
        [CustomNodeType.IMAGE_LOADER]: ImageLoaderNode,
        [CustomNodeType.IMAGE_VIEWER]: ImageViewNode,
        [CustomNodeType.TXT2IMG_INPUT]: Txt2ImgInputNode,
        [CustomNodeType.TEXT_INPUT]: TextInputNode,
        [CustomNodeType.TEXT_VIEWER]: TextViewCustomNode,
        [CustomNodeType.FLOAT_INPUT]: FloatInputCustomNode,
        [CustomNodeType.AI_MODEL]: DefaultCustomNode,
    }), []);

    const filteredModelData = modelData.filter(model => model.node_name.toLowerCase().includes(filter.toLowerCase()));

    // Flow Upload Flag
    const [requiresUploadFlow, setRequiresUploadFlow] = useState(false);

    // Flow Polling
    const [flowPolling, setFlowPolling] = useState(false);

    // Fetch Model List (include input/ouput nodes)
    const params = useParams();
    const navigate = useNavigate();

    // Toast Notify
    const [toastedPollStatus, setToastedPollStatus] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const node_list = await getFlowNodeList();
            if (!node_list) {
                return;
            }

            // In node_list, nodes with category input/output should be removed from a separate list and displayed as a button at the top of the model list.
            const io_nodes: IFlowNodeSource[] = [];
            const model_nodes = node_list.filter(n => {
                if (['input', 'output'].includes(n.category.toLowerCase())) {

                    // Todo: temporary removed txt2img node
                    if (n.node_type === 'Txt2ImgInput') {
                        return false;
                    }

                    io_nodes.push(n);
                    return false;
                }
                return true;
            });

            // IO Nodes set
            setIONodeData(io_nodes);

            // model list set
            setModelData(model_nodes);

            if (params.uuid) {
                const FlowData = await loadFlowData(params.uuid);
                if (isCopy) {
                    flow_uuid = uuidv4();
                    navigate(`${routes.AIFlow}/${flow_uuid}`, {replace: true});
                    setFlowCreated(Date.now());
                } else {
                    flow_uuid = params.uuid;
                }

                if (FlowData) {
                    toast('AI Flow Loading...')
                    setFlowName(FlowData.name);
                    await DrawFlow(FlowData, io_nodes, model_nodes);
                }
            } else {
                flow_uuid = uuidv4();
                navigate(`${routes.AIFlow}/${flow_uuid}`, {replace: true});
            }
        };
        fetchData();

        toast("AI-Flow ready!");
        return () => {
            resetFlow()
        };
    }, []);

    const resetFlow = () => {
        setNodes([]);
        setEdges([]);
        setFlowName('');
        flow_uuid = uuidv4();
        NodeManager.reset();
    }

    const DrawFlow = async (FlowData: IAPILoadFlow, io_nodes: IFlowNodeSource[], model_list: IFlowNodeSource[]) => {
        resetFlow()
        const node_map = new Map(FlowData.nodes.map(node => [node.id, node]));
        for (const loaded_node of node_map.values()) {
            let model_data;
            model_data = io_nodes.find(_node => _node.node_name === loaded_node.node_name);
            if (!model_data) {
                model_data = model_list.find(_node => _node.node_name === loaded_node.node_name);
            }

            if (!model_data) {
                console.error('cannot found model_data');
                return;
            }

            const position = {
                x: loaded_node.ui.x,
                y: loaded_node.ui.y,
                connection: loaded_node.ui.connection
            }

            const newNode = NodeManager.createNodeData(loaded_node.node_name, loaded_node.node_type as CustomNodeType, position, model_data, onCustomNodeChangeHandler, onCustomNodeCloseHandler);
            newNode.data = {
                ...newNode.data,
                ...loaded_node,
                parameter_values: loaded_node.parameters
            }
            newNode.id = loaded_node.id;

            NodeManager.addNode(newNode);
            setNodes((nds) => nds.concat(newNode));
        }

        for (const loaded_node of node_map.values()) {
            for (const connection of loaded_node.ui.connection) {
                const color_code = connection.sourceHandle ? NodeManager.colorByHandleID(connection.sourceHandle) : '';
                const new_edge = {
                    ...connection,
                    style: {
                        strokeWidth: "5",
                        stroke: color_code,
                    }
                };
                NodeManager.addEdge(new_edge as Connection);

                if (connection.source && connection.target && connection.targetHandle) {
                    const target_node = NodeManager.getNode(connection.target);
                    if (target_node.data.type === 'AI-Model') {
                        const input_type = connection.targetHandle.split('-')[1];

                        const inputNames = target_node.data.input.names;
                        const inputTypes = target_node.data.input.types;

                        const index = inputNames.indexOf(input_type);
                        if (index !== -1) {
                            const correspondingType = inputTypes[index];
                            const source_node = NodeManager.getNode(connection.source);
                            source_node.data.output.types[0] = correspondingType;
                        } else {
                            console.log('Input type not found in input names');
                        }
                    }
                }

                setTimeout(() => {
                    setEdges((eds) => addEdge(new_edge, eds));
                }, 10);
            }
        }
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!reactFlowInstance || !reactFlowWrapper.current) {
            console.error('onDrop: !reactFlowInstance || !reactFlowWrapper.current');
            return;
        }

        let position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY
        });

        position = {
            ...position,
            connection: []
        }

        const drag_data = event.dataTransfer.getData('application/reactflow');
        if (!drag_data) {
            console.error('onDrop: !drag_data');
            return;
        }

        const {model_id, node_type, node_name, category} = parseDragData(drag_data);
        const model_data = (['input', 'output'].includes(category.toLowerCase()) ? IONodeData : modelData).find(m => m.node_name === node_name);

        if (!model_data) {
            console.error(`Model data for ${node_name} not found. node_type: ${node_type}, model_name: ${node_name}`);
            return;
        }

        const newNode = NodeManager.createNodeData(node_name, node_type, position, model_data, onCustomNodeChangeHandler, onCustomNodeCloseHandler);
        NodeManager.addNode(newNode);

        setRequiresUploadFlow(true);
        setNodes((nds) => nds.concat(newNode));
    };

    const onConnect = useCallback(
        (connection: Connection) => {
            const sourceNode = nodes.find(node => node.id === connection.source);
            const targetNode = nodes.find(node => node.id === connection.target);
            if (!sourceNode || !targetNode) return false;
            const source_node_data: INodeData = sourceNode.data;

            if (source_node_data.status === CustomNodeStatus.PREPARING) {
                NodeManager.updateDescendantsState(targetNode.id, CustomNodeStatus.PREPARING);
            }

            const color_code = connection.sourceHandle ? NodeManager.colorByHandleID(connection.sourceHandle) : '';
            const new_edge = {
                ...connection,
                style: {
                    strokeWidth: "5",
                    stroke: color_code,
                }
            };
            NodeManager.addEdge(new_edge as Connection);
            setEdges((eds) => addEdge(new_edge, eds));

            const existingConnections = sourceNode.data.ui?.connection || [];
            const newConnections = [
                ...existingConnections,
                connection
            ];
            const uniqueConnections = newConnections.filter((c, index, self) =>
                    index === self.findIndex((conn) =>
                        conn.source === c.source &&
                        conn.target === c.target &&
                        conn.sourceHandle === c.sourceHandle &&
                        conn.targetHandle === c.targetHandle
                    )
            );

            if (connection.source && connection.target && connection.targetHandle) {
                const target_node = NodeManager.getNode(connection.target);
                if (target_node.data.type === 'AI-Model') {
                    const input_type = connection.targetHandle.split('-')[1];

                    // input_type에 해당하는 types 값을 가져옴
                    const inputNames = target_node.data.input.names;
                    const inputTypes = target_node.data.input.types;

                    const index = inputNames.indexOf(input_type);
                    if (index !== -1) {
                        const correspondingType = inputTypes[index];
                        const source_node = NodeManager.getNode(connection.source);
                        source_node.data.output.types[0] = correspondingType;
                    } else {
                        console.log('Input type not found in input names');
                    }
                }
            }

            sourceNode.data.ui = {
                ...sourceNode.data.ui,
                connection: uniqueConnections
            };
            NodeManager.updateNodeData(sourceNode, sourceNode.data);
            setRequiresUploadFlow(true);
        },
        [setEdges, nodes]
    );


    // Modal
    const onClickHandler = (event: React.MouseEvent, node: Node) => {
        event.stopPropagation();
        console.log(`onClick node:`, NodeManager.getNode(node.id));
        // console.log(`nodeManagerData:`, NodeManager.getNode(node.id));

        // Todo: Display GenAI Prompt Modal
        // if (node.type === CustomNodeType.TXT2IMG_INPUT) {
        //     setIsModalOpen(true);
        //     setModalContent(node.data);
        //     setModalState(CustomNodeGenAIModalState.PROMPT);
        // }
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setModalState(CustomNodeGenAIModalState.OFF);
    };

    const closeModalWithSave = (data: any) => {
        setNodes((prevNodes: Node[]): Node[] => {
            return prevNodes.map((node: Node): Node => {
                if (node.id === data.id) {
                    return NodeManager.updateNodeData(node, data);
                }
                return node;
            });
        })

        setIsModalOpen(false);
        setModalState(CustomNodeGenAIModalState.OFF);
    };

    const onCustomNodeCloseHandler = useCallback(
        (node_id: string) => {
            NodeManager.removeNode(node_id);
            setNodes(NodeManager.getNodes());
            setEdges(NodeManager.getEdges());
            setRequiresUploadFlow(true);
        }, [edges, nodes]
    );

    // Node onChange Handler
    const onCustomNodeChangeHandler = (data: INodeData) => {
        // console.log('onChange Handler called', data, edges.length);
        let updated_node: Node;

        const old_node_data = NodeManager.getNode(data.id);
        if (old_node_data?.status !== CustomNodeStatus.COMPLETED && data.status === CustomNodeStatus.COMPLETED) {
            setRequiresUploadFlow(true);
        }

        if ([CustomNodeType.IMAGE_LOADER,
            CustomNodeType.TEXT_INPUT, CustomNodeType.FLOAT_INPUT].includes(data.type) && old_node_data.data.status === CustomNodeStatus.COMPLETED && data.status === CustomNodeStatus.PREPARING) {
            NodeManager.updateDescendantsState(data.id, CustomNodeStatus.PREPARING);
            setNodes(NodeManager.getNodes());
        }

        setNodes((prevNodes: Node[]): Node[] => {
            return prevNodes.map((node: Node): Node => {
                if (node.id === data.id) {
                    updated_node = NodeManager.updateNodeData(node, data)
                    return updated_node;
                }
                return node;
            });
        });
    };

    // Model Search Filter onChange Handler
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    // Delete Node
    const onNodesDelete = useCallback(
        (deletedNodes: Node[]) => {
            NodeManager.removeNodes(deletedNodes);
            setNodes(NodeManager.getNodes());
            setEdges(NodeManager.getEdges());
            setRequiresUploadFlow(true);
        },
        [edges, nodes]
    );

    const onEdgesDelete = useCallback(
        (deletedEdges: Edge[]) => {
            NodeManager.removeEdges(deletedEdges)
            setNodes(NodeManager.getNodes());
            setEdges(NodeManager.getEdges());
            setRequiresUploadFlow(true);
        },
        [setEdges, edges]
    );

    const onNodeDragStop = useCallback(
        (event: React.MouseEvent, node: Node, nodes: Node[]) => {
            NodeManager.updateNodeData(node, node.data);
        }, [setNodes, nodes]
    )

    // Flow Upload
    useEffect(() => {
        try {
            setFlowPolling(false);
            const new_flow_data = convertToIFlowNodes(NodeManager.getNodes(), NodeManager.getEdges());
            if (JSON.stringify(flowData) !== JSON.stringify(new_flow_data)) {
                if (requiresUploadFlow) {
                    setRequiresUploadFlow(false);
                    toast("AI-Flow upload preparing.");
                    uploadFlowData(flowCreated, flow_uuid, flowName, new_flow_data, () => {
                        // toast_notify("Flow uploaded successfully.");
                        setFlowPolling(NodeManager.requirePolling());
                    }).catch((reason: Error) => {
                        toast.error(reason.message, {autoClose: false});
                    });
                }
                setFlowData(new_flow_data);
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                toast.error(error.message, {autoClose: false});
            } else {
                toast.error('An unknown error occurred', {autoClose: false});
            }
        }
    }, [edges, nodes, flowName]);

    const fetchPollData = useCallback(async () => {
        try {
            clearTimeout(pollingTimer);
            if (NodeManager.requirePolling()) {
                console.log('NodeManager.requirePolling(): True');
                if (!toastedPollStatus) {
                    console.log('toastedPollStatus false, toast notify and set true.');
                    toast("AI-Flow saved.");
                    // Todo: Modify to save only the flow until inference on the web is possible.
                    // setToastedPollStatus(true);
                }
            } else {
                toast("AI-Flow Processing done.");
                console.log('NodeManager.requirePolling(): False');

                setFlowPolling(false);
                setToastedPollStatus(false);
                console.log('toastedPollStatus set false');
                return;
            }

            const data = await pollFlowData(flow_uuid);
            if (data) {
                const nodesData = data.nodes;
                setNodes((prevNodes: Node[]): Node[] => {
                    const updatedNodes = prevNodes.map((_node: Node): Node => {
                        const matchingNode = nodesData.find(node => node.id === _node.id);
                        if (matchingNode) {
                            return NodeManager.updateNodeData(_node, matchingNode);
                        }
                        return _node;
                    });
                    return updatedNodes;
                });
                pollingTimer = setTimeout(() => {
                    if (flowPolling) {
                        fetchPollData();
                    }
                }, FLOW_DATA_POLLING_INTERVAL * 1000);
            } else {
                // console.log('Polling useEffect no data => setFlowPolling(false)');
                setFlowPolling(false);
            }
        } catch (e) {
            console.error(e);
            setFlowPolling(false);
        }
    }, [flow_uuid, flowPolling, toastedPollStatus]);

    useEffect(() => {
        if (flowPolling) {
            fetchPollData();
        }

        return () => {
            clearTimeout(pollingTimer);
        };
    }, [flowPolling, fetchPollData]);

    const handleLibraryClick = async () => {
        if (auth.user) {
            setLibraryModalVisible(!isLibraryModalVisible);
        }
    }

    const handleExecuteClick = async () => {
        // Todo: disable execution before desktop application deployment
        if (auth.user) {
            window.open(`${APPLICATION_EXECUTE_PREFIX}flow_id=${flow_uuid}`)
        }
    }

    const handleLibraryRenameFlow = (flow_id: string, name: string): void => {
        if (flow_id === flow_uuid) {
            setFlowName(name)
        }
    }

    const handleLibraryLoadFlowClick = async (flow_id: string) => {
        setLibraryModalVisible(!isLibraryModalVisible);

        const flow_data = await loadFlowData(flow_id);
        if (flow_data) {
            await DrawFlow(flow_data, IONodeData, modelData);
            setFlowName(flow_data.name);
            flow_uuid = flow_id;
        }

        navigate(`${routes.AIFlow}/${flow_id}`, {replace: true});
    }

    const handleMoveEnd = useCallback(() => {
        if (reactFlowInstance) {
            const {zoom} = reactFlowInstance.getViewport();
            setZoomLevel(zoom);
        }
    }, [reactFlowInstance]);

    const handleSaveFlow = async (flowName: string) => {
        setFlowName(flowName)

        await renameFlow(flow_uuid, flowName);
    }

    return (
        <div className={styles.main}>
            <div className={`${styles.model_list_wrapper} ${styles.element}`}>
                {IONodes(IONodeData)}
                <div className={styles.line}>&nbsp;</div>
                <input
                    type="text"
                    placeholder="Search"
                    value={filter}
                    onChange={handleFilterChange}
                    className={styles.searchInput}
                />
                <div className={styles.model_categories}>
                    <div className={styles.categories}>
                        Categories
                    </div>
                    <div className={styles.model_list}>
                        <ModelList _filteredModelData={filteredModelData}/>
                    </div>
                </div>
            </div>
            <ReactFlowProvider>
                <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{width: "100%", height: "100%"}}>
                    <ReactFlow
                        proOptions={{hideAttribution: true}}
                        onInit={setReactFlowInstance}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onNodesDelete={onNodesDelete}
                        onEdgesDelete={onEdgesDelete}
                        onNodeDragStop={onNodeDragStop}
                        nodeTypes={nodeTypes}
                        isValidConnection={NodeManager.isValidConnection}
                        nodes={nodes}
                        edges={edges}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        onConnect={onConnect}
                        snapToGrid={true}
                        snapGrid={[2, 2]}
                        onNodeClick={onClickHandler}
                        deleteKeyCode="Delete"
                        nodeDragThreshold={5}
                        edgeUpdaterRadius={30}
                        onMoveEnd={handleMoveEnd}
                    >
                        <Background color="#ccc" gap={15} size={1}/>
                        <LibraryIcon onClick={handleLibraryClick}/>
                        <ExecuteApplicationIcon onClick={handleExecuteClick}/>
                        {reactFlowInstance &&
                          <CustomControls reactFlowInstance={reactFlowInstance} zoomLevel={zoomLevel}
                                          setZoomLevel={setZoomLevel} onSaveFlow={handleSaveFlow}/>}
                    </ReactFlow>
                </div>

                {isModalOpen && (
                    <ModalForGenAINode
                        state={modalState}
                        data={modalContent}
                        onSave={closeModalWithSave}
                        onCancel={closeModal}
                    />
                )}

                {isLibraryModalVisible && (
                    <LibraryModal isVisible={isLibraryModalVisible} onClose={handleLibraryClick}
                                  onFlowLoad={handleLibraryLoadFlowClick}
                                  onFlowRename={handleLibraryRenameFlow}
                    />
                )}

            </ReactFlowProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Slide}
            />
        </div>
    );
}

export default AIFlow;
