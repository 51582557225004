import React, {useEffect, useState} from 'react';
import styles from './SignInPage.module.css';
import {APISupremumSignInUsingGoogleCredential, useAuth} from "../services/authService";
import {GoogleLogin} from '@react-oauth/google';
import {APPLICATION_EXECUTE_PREFIX} from "../config/defaultConfig";

const SignInPage = () => {
    const auth = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionId, setSessionId] = useState('');

    const handleOpenApp = () => {
        window.open(`${APPLICATION_EXECUTE_PREFIX}session_id=${sessionId}`)
    };

    useEffect(() => {
        if (auth && auth.user) {
            setIsLoggedIn(true);
            console.log(auth.user.id);
            console.log(auth.user.profile);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.signin}>
                    <div className={styles.signin_symbol}>
                        <a href='/'><img src='/images/cnapsai_symbol.svg' alt='symbol'/></a>
                    </div>
                    <div className={styles.signin_title}>
                        Sign in to your account
                    </div>
                    <div className={styles.signin_desc}>
                        {!isLoggedIn
                            ? "Use your social account or email service to continue with CNAPS.AI"
                            : "Login successful! Launch the AI Flow Studio below."
                        }
                    </div>
                    {auth && auth.user ? (
                        <button className={styles.openStudioButton} onClick={handleOpenApp}>
                            Open the AI Flow Studio
                        </button>
                    ) : (
                        <GoogleLogin
                            shape="rectangular"
                            text="signin_with"
                            theme="filled_black"
                            onSuccess={async (credentialResponse) => {
                                if (credentialResponse) {
                                    try {
                                        await APISupremumSignInUsingGoogleCredential(credentialResponse).then(response => {
                                            const user_data = response.data.user;
                                            auth.signIn({
                                                ...user_data,
                                                session_id: response.data.session_id
                                            });
                                            setSessionId(response.data.session_id);

                                            // 로그인 완료 후 상태 변경
                                            setIsLoggedIn(true);
                                        });
                                    } catch (error) {
                                        console.error('Login failed', error);
                                    }
                                } else {
                                    console.error('Login failed');
                                }
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            useOneTap
                        />
                    )}
                </div>
            </div>
        </main>
    );
};

export default SignInPage;
